import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { getNavLink } from '@/layout/NavLinks';
import { miniCardStyles } from '@/MiniCard';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const AboutUs = () => {
    return (
        <Fragment>
            <CommonHero heading='About Beauty Religion'>
                <StaticImage
                    src="../../../assets/images/about-us/index/hero.jpg"
                    alt=" yeliana nikolskaya"
                    width={846}
                    height={430}
                    loading="eager"
                />
            </CommonHero>
            <Article miniCardsGrid big>
                <CLink to={getNavLink('yeliana nikolskaya')} css={miniCardStyles}>
                    <StaticImage
                        src="../../../assets/images/about-us/index/yeliana.jpg"
                        alt="yeliana nikolskaya"
                        width={328}
                        height={328}
                    />
                    <h4>Meet Yeliana Nikolskaya</h4>
                </CLink>
                <CLink to={getNavLink('dr. paul laband')} css={miniCardStyles}>
                    <StaticImage
                        src="../../../assets/images/about-us/dr-paul/hero.jpg"
                        alt="Medical Director, Paul Laband, MD"
                        width={328}
                        height={328}
                        transformOptions={{ cropFocus: 'north' }}
                    />
                    <h4>Meet Dr. Paul Laband</h4>
                </CLink>
                <CLink to={getNavLink('dawn marie')} css={miniCardStyles}>
                    <StaticImage
                        src="../../../assets/images/about-us/index/dawn.jpg"
                        alt="dawn marie"
                        width={328}
                        height={328}
                    />
                    <h4>Meet Dawn Marie</h4>
                </CLink>
                <CLink to={getNavLink('our philosophy')} css={miniCardStyles}>
                    <StaticImage
                        src="../../../assets/images/about-us/index/philosophy.jpg"
                        alt="yeliana nikolskaya"
                        width={328}
                        height={328}
                    />
                    <h4>Our Philosophy</h4>
                </CLink>
            </Article>
        </Fragment>
    );
};

export default AboutUs;
